var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.show_list)?_c('div',[_c('CCard',[_c('CCardHeader',[_c('strong',{staticStyle:{"line-height":"36px"}},[_vm._v(_vm._s(_vm.$t("cloud.title.lpr_sync")))]),(_vm.count > 0)?_c('span',[_vm._v(" ("+_vm._s(_vm.count)+") ")]):_vm._e(),_c('CDropdown',{staticClass:"d-inline-block float-right mx-1",attrs:{"toggler-text":_vm.$t('button.add'),"color":"primary","disabled":_vm.count>0}},[_c('CDropdownItem',{on:{"click":_vm.onAdd}},[_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-plus"}}),_vm._v(" "+_vm._s(_vm.$t('cloud.title.lpr_add_sync'))+" ")],1)],1)],1),_c('CCardBody',{staticStyle:{"min-height":"300px"}},[_c('CDataTable',{attrs:{"items":_vm.list,"fields":_vm.FIELDS,"items-per-page":10},scopedSlots:_vm._u([{key:"bridges",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.all_bridges?_vm.$t('cloud.title.all_device'):item.bridges.length)+" ")])}},{key:"groups",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.all_groups?_vm.$t('cloud.title.all_lp'):item.groups.length)+" ")])}},{key:"ops",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{staticClass:"text-center align-middle"},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":function (command) { return _vm.onOps(command, index, item); }}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-more text-dark"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-edit-outline","command":"edit"}},[_vm._v(_vm._s(_vm.$t('button.edit')))]),_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete","command":"delete"}},[_vm._v(_vm._s(_vm.$t('button.delete')))])],1)],1)],1)}}],null,false,3789690501)})],1)],1),(_vm.pageTotal > 1)?_c('CRow',{staticClass:"justify-content-end"},[_c('CPagination',{attrs:{"activePage":_vm.pageCurrent,"pages":_vm.pageTotal},on:{"update:activePage":[function($event){_vm.pageCurrent=$event},_vm.onUpdatePage],"update:active-page":function($event){_vm.pageCurrent=$event}}}),_c('CInput',{staticClass:"px-3 w-auto",staticStyle:{"max-width":"120px"},attrs:{"type":"number"},on:{"change":_vm.onChangePageDirectSet},model:{value:(_vm.pageDirectSet),callback:function ($$v) {_vm.pageDirectSet=$$v},expression:"pageDirectSet"}})],1):_vm._e()],1):_c('Form',{attrs:{"init-data":_vm.initData},on:{"payload":_vm.onPayload,"cancel":function($event){_vm.show_list = true}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }